
import { defineComponent } from 'vue'
import Info from '@/assets/svg/info.svg?inline'
import Switchbot from '@/assets/svg/switchbot.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'

export default defineComponent({
  name: 'IntegrationsTable',
  data () {
    return {
      publish: false
    }
  },
  props: {
    integrationsData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    unlinkIntegrations: {
      type: Function,
      default: () => {
        return false
      }
    },
    handleCloseUnlinkModalSwithBot: {
      type: Function,
      default: () => {
        return false
      }
    },
    isModalIntegrationSwithBot: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Info,
    DefaultPopup,
    Switchbot
  }
})
