
import { defineComponent } from 'vue'
import SearchContainer from '@/components/molecules/SearchContainer.vue'
import NoIntegration from '@/assets/svg/noIntegration.svg?inline'
import IntegrationsTable from '@/components/molecules/table/IntegrationsTable.vue'
import Pagination from '@/components/molecules/Pagination.vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import { Form, Field } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'IntegrationsSection',
  data () {
    return {
      isLoading: true,
      searchData: '',
      integrationsData: {},
      integrationsMeta: null,
      page: 1,
      isModalIntegration: false,
      integrationsListData: {},
      token: null,
      switchBotTemplate: false,
      errorSwitchBot: null,
      clientId: '',
      clientSecret: '',
      email: '',
      intervalId: null,
      integrationId: null,
      isModalIntegrationUnlink: false,
      errorUnlink: null,
      errorSwitchBotMessage: null,
      isModalIntegrationSwithBot: false
    }
  },
  components: {
    LottieAnimation: Vue3Lottie,
    SearchContainer,
    IntegrationsTable,
    Pagination,
    DefaultPopup,
    Form,
    Field,
    AlertBlock,
    NoIntegration
  },
  watch: {
    searchData: {
      deep: true,
      handler () {
        if (this.searchData.length > 1 || this.searchData.length === 0) {
          this.page = 1
          this.getIntegrations()
        }
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.intervalId)
  },
  mounted () {
    this.getIntegrationsList()
    this.token = JSON.parse(localStorage.getItem('user'))
    this.intervalId = setInterval(() => {
      this.getIntegrations()
    }, 5000)
  },
  methods: {
    getIntegrations (searchData) {
      let url = ''
      url += `?page=${this.page}`
      if (searchData !== undefined) {
        url += `&search=${searchData}`
      }
      this.$store.dispatch('integrations/getIntegrations', url).then(
        (res) => {
          this.integrationsData = res.data
          this.integrationsMeta = res.meta
          this.isLoading = false
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    getIntegrationsList () {
      this.$store.dispatch('integrations/getIntegrationsList').then(
        (res) => {
          this.integrationsListData = res.data
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    unlinkIntegrationsPopup (integrationId) {
      this.integrationId = integrationId
      this.isModalIntegrationUnlink = true
    },
    handleCloseUnlinkModal () {
      this.isModalIntegrationUnlink = false
      this.errorUnlink = null
    },
    unlinkIntegrations () {
      this.$store.dispatch('integrations/unlinkIntegrations', this.integrationId).then(
        () => {
          this.getIntegrations()
          this.isModalIntegrationUnlink = false
        },
        (error) => {
          this.errorUnlink = error.response.data
        }
      )
    },
    authorizeNuki (url) {
      this.isModalIntegration = false
      window.open(url + '?token=' + this.token, 'left/top', 'noopener,noreferrer,left=300,width=500,height=600')
    },
    handlePage (page) {
      this.page = page
      this.getIntegrations()
    },
    createIntegrations () {
      this.isModalIntegration = true
    },
    handleCloseModal () {
      this.isModalIntegration = false
      this.switchBotTemplate = false
      this.clientId = ''
      this.clientSecret = ''
      this.email = ''
      this.errorSwitchBot = null
    },
    openModalSwitchBot () {
      this.switchBotTemplate = true
    },
    authorizeSwitchBot (data) {
      data = {
        client_id: data.clientId,
        secret_id: data.clientSecret,
        email: data.email
      }
      this.$store.dispatch('integrations/authorizeSwitchBot', data).then(
        (res) => {
          console.log(res)
          this.isModalIntegration = false
          this.switchBotTemplate = false
          this.clientId = ''
          this.clientSecret = ''
          this.email = ''
          this.errorSwitchBot = null
          this.isModalIntegrationSwithBot = true
        },
        (error) => {
          this.errorSwitchBot = error.response.data.errors
          this.errorSwitchBotMessage = error.response.data.message
        }
      )
    },
    handleCloseUnlinkModalSwithBot () {
      this.isModalIntegrationSwithBot = false
    }
  }
})
